$(document).ready(function () {
  let $body = $('body');

  // events on body
  $body
    .addClass('loaded')
    .on('click', '.js-social--auth', function (e) {
      // авторизация через социалки
      e.preventDefault();

      let href = $(this).attr('href') || $(this).attr('data-href');
      window.open(href, '', 'toolbar=0,status=0,width=626,height=436')
    })
    .on('click', '*[data-gtagcategory]', function (e) {
      // gtag
      var data = $(this).data();
      if (typeof gtag === 'undefined') {
        return false;
      }
      gtag('event', 'click', {
        'event_category': data.gtagcategory,
        'event_label': data.gtaglabel
      });
    });

  // подтверждение аккаунта
  (function () {
    let confirmed = coreFunctions.getParameterByName("confirmed");
    if (confirmed !== '') {
      window.setTimeout(function () {
        jewelryModals.show('.js-modal--registration-congratulation');
        coreFunctions.setHistoryState(document.title, "/");
      }, 50);
    }
  })();

  // inputmask
  $('input[data-mask]').each(function () {
    var $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  // datepicker
  $.datepicker.regional['ru'] = {
    closeText: 'Закрыть',
    prevText: 'Предыдущий',
    nextText: 'Следующий',
    currentText: 'Сегодня',
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    weekHeader: 'Не',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['ru']);
  $('.datepicker').each(function () {
    $(this).datepicker({
      changeMonth: true,
      changeYear: true,
      yearRange: "-1:+0"
    });
  });
});

$(document).ready(function () {

  $('body')
    .on('blur click change keyup', '.form input[data-required], .form textarea[data-required], .form select[data-required]', function (e) {
      let $t = $(this), itype = $t.get(0).type, val = $t.val(), $f = $t.closest('form'),
        $row = $t.closest('.form__row'),
        enabled = true, $btn = $f.find('input.form__btn, button.form__btn');

      if (itype === 'radio') {
        $row.find('input[type=radio]').removeAttr('data-required');
      } else if (itype === 'checkbox') {
        let $chs = $row.find('input[type=checkbox]').attr('data-required', 'required');
        if ($chs.filter(':checked').length) {
          $chs.removeAttr('data-required');
        }

        if (val.toLowerCase() === 'другое') {
          let $inp = $row.find('input[type=text]');
          if ($t.is(':checked')) {
            $inp.attr('data-required', 'required');
          } else {
            $inp.removeAttr('data-required');
          }
        }
      }

      $f.find('input[data-required], textarea[data-required], select[data-required]').each(function () {
        if ($.trim($(this).val()).length < 1) {
          enabled = false;
        }
      });

      if ($f.find('input[type=checkbox][data-required]').not(':checked').length > 0) {
        enabled = false;
      }
      if ($f.find('input[type=radio][data-required]').not(':checked').length > 0) {
        enabled = false;
      }

      if (enabled) {
        $btn.removeClass('form__btn--disabled').removeAttr('disabled');
      } else {
        $btn.addClass('form__btn--disabled').attr('disabled', 'disabled');
      }
    })
    .on('click', '.form__btn', function (e) {
      e.preventDefault();
      let s = $(this).closest('form').serialize(), $f = $('.js--sendable_form');

      $f.find('#vote1_answer').val(s);
      $f.trigger('submit');
    });
});

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
require("jquery");
require("jquery-ui");
require("jquery-ujs");

window.Instascan = require("@cadwork/instascan");
window.raf = require("raf");
window.isMobile = require("ismobilejs");

require("packs/jq/DragDropTouch");
// require('packs/jq/social-share-button');
require('packs/jq/jewelryModals');
//require('packs/jq/jewelryTabs');
require('packs/jq/core_functions');
require('packs/jq/loader');
require('packs/jq/multipart-form');
require('packs/jq/js_onload');
require('packs/jq/js_other');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
